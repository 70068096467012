import React from 'react';

interface Props {
    iconColor: string,
    iconWidth: string
}

const DownloadFileIcon : React.FC<Props> = ({iconColor, iconWidth}) => {
    return (
        <svg 
            version="1.1" 
            x="0px" 
            y="0px"
            viewBox="0 0 404.8 511" 
            style = {{
                "fill": iconColor,
                "width": iconWidth
            }}
        >
            <g id="surface1_1_">
                <path d="M275.4,3.9C273,1.5,269.8,0,266.5,0H67.1C30.4,0,0,30.2,0,67v377c0,36.8,30.4,67,67.1,67h270.6c36.8,0,67.1-30.2,67.1-67
                    V144.7c0-3.2-1.5-6.3-3.6-8.6L275.4,3.9z M279,43.6l84.3,88.5h-54.8c-16.3,0-29.5-13.1-29.5-29.4V43.6z M337.7,486.3H67.1
                    c-23.1,0-42.5-19.1-42.5-42.3V67c0-23.1,19.3-42.3,42.5-42.3h187.1v78c0,30,24.2,54.1,54.2,54.1h71.7V444
                    C380.2,467.2,360.9,486.3,337.7,486.3z"/>
                <path d="M304,401.1H100.8c-6.8,0-12.3,5.6-12.3,12.3c0,6.8,5.6,12.3,12.3,12.3h203.3c6.8,0,12.3-5.6,12.3-12.3
                    C316.5,406.7,310.9,401.1,304,401.1z"/>
                <path d="M193.4,356.8c2.3,2.5,5.6,3.9,9,3.9c3.5,0,6.7-1.5,9-3.9l72.3-77.6c4.7-4.9,4.3-12.8-0.6-17.4c-4.9-4.7-12.8-4.3-17.4,0.6
                    l-51,54.7V182.3c0-6.8-5.6-12.3-12.3-12.3c-6.8,0-12.3,5.6-12.3,12.3v134.8l-50.9-54.7c-4.7-4.9-12.5-5.3-17.4-0.6
                    c-4.9,4.7-5.3,12.5-0.6,17.4L193.4,356.8z"/>
            </g>
        </svg>
    )
}

export default DownloadFileIcon;