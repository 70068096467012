import React from 'react';

interface Props {
    iconColor: string,
    iconWidth: string
}

const GithubIcon : React.FC<Props> = ({iconColor, iconWidth}) => {
    return (
        <svg 
            version="1.1" 
            x="0px" 
            y="0px"
            viewBox="0 0 512 490.7" 
            style = {{
                "fill": iconColor,
                "width": iconWidth
            }}
        >
            <g>
                <path d="M256,0C114.6,0,0,112.6,0,251.6C0,362.7,73.3,457,175,490.2c12.8,2.4,17.5-5.4,17.5-12.1
                    c0-6-0.2-21.8-0.3-42.8C121,450.5,106,401.6,106,401.6c-11.6-29-28.5-36.8-28.5-36.8c-23.2-15.6,1.8-15.3,1.8-15.3
                    c25.7,1.7,39.2,25.9,39.2,25.9c22.8,38.5,59.9,27.3,74.6,20.9c2.3-16.3,8.9-27.3,16.2-33.6c-56.9-6.3-116.6-27.9-116.6-124.3
                    c0-27.5,9.9-49.9,26.3-67.5c-2.9-6.4-11.5-31.9,2.2-66.6c0,0,21.4-6.7,70.4,25.8c20.5-5.6,42.2-8.4,64-8.5
                    c21.8,0.1,43.5,2.9,64,8.5c48.6-32.5,70.1-25.8,70.1-25.8c13.8,34.6,5.1,60.2,2.6,66.6c16.3,17.6,26.2,40,26.2,67.5
                    c0,96.6-59.8,117.9-116.8,124.1c9,7.6,17.3,23,17.3,46.5c0,33.7-0.3,60.7-0.3,68.9c0,6.6,4.5,14.5,17.6,11.9
                    C438.7,456.9,512,362.6,512,251.6C512,112.6,397.4,0,256,0z"/>
            </g>
        </svg>
    )
}

export default GithubIcon;