import firebaseIcon from "../../../resources/icons/firebase.svg";
import cssIcon from "../../../resources/icons/css3.svg";
import htmlIcon from "../../../resources/icons/html5.svg";
import javascriptIcon from "../../../resources/icons/javascript.svg";
import typescriptIcon from "../../../resources/icons/typescript.svg";
import sassIcon from "../../../resources/icons/sass.svg";
import reactIcon from "../../../resources/icons/react.svg";
import javaIcon from "../../../resources/icons/java.svg";
import pythonIcon from "../../../resources/icons/python.svg";
import dockerIcon from "../../../resources/icons/docker.svg";

// TODO: Add projects from RPL
// TODO: Add varma-golv project

// ==============
// === IMAGES ===
// ==============

// Chapter Website
import chapterWebsite1 from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite1.png";
import chapterWebsite2 from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite2.png";
import chapterWebsite3 from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite3.png";
import chapterWebsite4 from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite4.png";
import chapterWebsite5 from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite5.png";
import chapterWebsite1Low from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite1Low.jpg";
import chapterWebsite2Low from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite2Low.jpg";
import chapterWebsite3Low from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite3Low.jpg";
import chapterWebsite4Low from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite4Low.jpg";
import chapterWebsite5Low from "../../../resources/images/projects_images/chapterWebsite/chapterWebsite5Low.jpg";

// Chess App
import chessApp1 from "../../../resources/images/projects_images/chessApp/chessApp1.jpg";
import chessApp1Low from "../../../resources/images/projects_images/chessApp/chessApp1Low.jpg";
import chessApp2 from "../../../resources/images/projects_images/chessApp/chessApp2.png";
import chessApp2Low from "../../../resources/images/projects_images/chessApp/chessApp2Low.jpg";

// Learn AI
import learnAi1 from "../../../resources/images/projects_images/learnAi/learnAi1.png";
import learnAi2 from "../../../resources/images/projects_images/learnAi/learnAi2.png";
import learnAi3 from "../../../resources/images/projects_images/learnAi/learnAi3.png";
import learnAi4 from "../../../resources/images/projects_images/learnAi/learnAi4.png";
import learnAi5 from "../../../resources/images/projects_images/learnAi/learnAi5.png";
import learnAi6 from "../../../resources/images/projects_images/learnAi/learnAi6.png";
import learnAi7 from "../../../resources/images/projects_images/learnAi/learnAi7.png";
import learnAi8 from "../../../resources/images/projects_images/learnAi/learnAi8.png";
import learnAi9 from "../../../resources/images/projects_images/learnAi/learnAi9.png";
import learnAi1Low from "../../../resources/images/projects_images/learnAi/learnAi1Low.jpg";
import learnAi2Low from "../../../resources/images/projects_images/learnAi/learnAi2Low.jpg";
import learnAi3Low from "../../../resources/images/projects_images/learnAi/learnAi3Low.jpg";
import learnAi4Low from "../../../resources/images/projects_images/learnAi/learnAi4Low.jpg";
import learnAi5Low from "../../../resources/images/projects_images/learnAi/learnAi5Low.jpg";
import learnAi6Low from "../../../resources/images/projects_images/learnAi/learnAi6Low.jpg";
import learnAi7Low from "../../../resources/images/projects_images/learnAi/learnAi7Low.jpg";
import learnAi8Low from "../../../resources/images/projects_images/learnAi/learnAi8Low.jpg";
import learnAi9Low from "../../../resources/images/projects_images/learnAi/learnAi9Low.jpg";

// RL Lab
import rlLab1 from "../../../resources/images/projects_images/rlLab/rlLab1.png";
import rlLab1Low from "../../../resources/images/projects_images/rlLab/rlLab1Low.jpg";
import rlLab2 from "../../../resources/images/projects_images/rlLab/rlLab2.png";
import rlLab2Low from "../../../resources/images/projects_images/rlLab/rlLab2Low.jpg";
import rlLab3 from "../../../resources/images/projects_images/rlLab/rlLab3.png";
import rlLab3Low from "../../../resources/images/projects_images/rlLab/rlLab3Low.jpg";

// Rudling Tech
import rudlingTech1 from "../../../resources/images/projects_images/rudlingTech/rudlingTech1.png";
import rudlingTech1Low from "../../../resources/images/projects_images/rudlingTech/rudlingTech1Low.jpg";
import rudlingTech2 from "../../../resources/images/projects_images/rudlingTech/rudlingTech2.png";
import rudlingTech2Low from "../../../resources/images/projects_images/rudlingTech/rudlingTech2Low.jpg";

// SwiftWriter
import swiftWriter1 from "../../../resources/images/projects_images/swiftWriter/swiftWriter1.png";
import swiftWriter2 from "../../../resources/images/projects_images/swiftWriter/swiftWriter2.png";
import swiftWriter3 from "../../../resources/images/projects_images/swiftWriter/swiftWriter3.png";
import swiftWriter4 from "../../../resources/images/projects_images/swiftWriter/swiftWriter4.png";
import swiftWriter5 from "../../../resources/images/projects_images/swiftWriter/swiftWriter5.png";
import swiftWriter1Low from "../../../resources/images/projects_images/swiftWriter/swiftWriter1Low.jpg";
import swiftWriter2Low from "../../../resources/images/projects_images/swiftWriter/swiftWriter2Low.jpg";
import swiftWriter3Low from "../../../resources/images/projects_images/swiftWriter/swiftWriter3Low.jpg";
import swiftWriter4Low from "../../../resources/images/projects_images/swiftWriter/swiftWriter4Low.jpg";
import swiftWriter5Low from "../../../resources/images/projects_images/swiftWriter/swiftWriter5Low.jpg";

// The Card Game
import theCardGame1 from "../../../resources/images/projects_images/theCardGame/theCardGame1.png";
import theCardGame2 from "../../../resources/images/projects_images/theCardGame/theCardGame2.png";
import theCardGame3 from "../../../resources/images/projects_images/theCardGame/theCardGame3.png";
import theCardGame1Low from "../../../resources/images/projects_images/theCardGame/theCardGame1Low.jpg";
import theCardGame2Low from "../../../resources/images/projects_images/theCardGame/theCardGame2Low.jpg";
import theCardGame3Low from "../../../resources/images/projects_images/theCardGame/theCardGame3Low.jpg";

// ShareTunes
import shareTunes1 from "../../../resources/images/projects_images/shareTunes/shareTunes1.png";
import shareTunes2 from "../../../resources/images/projects_images/shareTunes/shareTunes2.png";
import shareTunes3 from "../../../resources/images/projects_images/shareTunes/shareTunes3.png";
import shareTunes4 from "../../../resources/images/projects_images/shareTunes/shareTunes4.png";
import shareTunes5 from "../../../resources/images/projects_images/shareTunes/shareTunes5.png";
import shareTunes6 from "../../../resources/images/projects_images/shareTunes/shareTunes6.png";
import shareTunes7 from "../../../resources/images/projects_images/shareTunes/shareTunes7.png";
import shareTunes8 from "../../../resources/images/projects_images/shareTunes/shareTunes8.png";
import shareTunes1Low from "../../../resources/images/projects_images/shareTunes/shareTunes1Low.png";
import shareTunes2Low from "../../../resources/images/projects_images/shareTunes/shareTunes2Low.png";
import shareTunes3Low from "../../../resources/images/projects_images/shareTunes/shareTunes3Low.png";
import shareTunes4Low from "../../../resources/images/projects_images/shareTunes/shareTunes4Low.png";
import shareTunes5Low from "../../../resources/images/projects_images/shareTunes/shareTunes5Low.png";
import shareTunes6Low from "../../../resources/images/projects_images/shareTunes/shareTunes6Low.png";
import shareTunes7Low from "../../../resources/images/projects_images/shareTunes/shareTunes7Low.png";
import shareTunes8Low from "../../../resources/images/projects_images/shareTunes/shareTunes8Low.png";

// Image Colorization
import imageColorization1 from "../../../resources/images/projects_images/imageColorization/imageColorization1.png";
import imageColorization2 from "../../../resources/images/projects_images/imageColorization/imageColorization2.png";
import imageColorization3 from "../../../resources/images/projects_images/imageColorization/imageColorization3.png";
import imageColorization1Low from "../../../resources/images/projects_images/imageColorization/imageColorization1Low.png";
import imageColorization2Low from "../../../resources/images/projects_images/imageColorization/imageColorization2Low.png";
import imageColorization3Low from "../../../resources/images/projects_images/imageColorization/imageColorization3Low.png";

export interface ProjectImage {
	original: string;
	thumbnail?: string;
	sizes?: string;
}

export interface Project {
	name: string;
	text: string;
	textLong: string;
	bullets: Array<string>;
	mainLink: string;
	demoLink: string | undefined;
	gitHubLink: string | undefined;
	images: Array<ProjectImage>;
	technologies: Array<string>;
	type: string;
}

interface Projects {
	[key: string]: Project;
}

export interface SvEnText {
	sv: string;
	en: string;
}

export interface SvEnProject {
	name: SvEnText;
	text: SvEnText;
	textLong: SvEnText;
	bullets: Array<SvEnText>;
	mainLink: string;
	demoLink: string | undefined;
	gitHubLink: string | undefined;
	images: Array<ProjectImage>;
	technologies: Array<string>;
	type: SvEnText;
}

interface SvEnProjects {
	[key: string]: SvEnProject;
}

interface Technology {
	icon: string;
	tooltipText: string;
	tooltipLink: string;
}

interface TechnologiesInfo {
	[key: string]: Technology;
}

export const technologiesInfo: TechnologiesInfo = {
	Firebase: {
		icon: firebaseIcon,
		tooltipText: "Firebase",
		tooltipLink: "https://en.wikipedia.org/wiki/Firebase",
	},
	CSS: {
		icon: cssIcon,
		tooltipText: "CSS",
		tooltipLink: "https://en.wikipedia.org/wiki/Cascading_Style_Sheets",
	},
	HTML: {
		icon: htmlIcon,
		tooltipText: "HTML",
		tooltipLink: "https://en.wikipedia.org/wiki/HTML",
	},
	JavaScript: {
		icon: javascriptIcon,
		tooltipText: "Javascript",
		tooltipLink: "https://en.wikipedia.org/wiki/Javascript",
	},
	TypeScript: {
		icon: typescriptIcon,
		tooltipText: "Typescript",
		tooltipLink: "https://en.wikipedia.org/wiki/TypeScript",
	},
	Sass: {
		icon: sassIcon,
		tooltipText: "Sass",
		tooltipLink: "https://en.wikipedia.org/wiki/Sass_(stylesheet_language)",
	},
	ReactJS: {
		icon: reactIcon,
		tooltipText: "React",
		tooltipLink: "https://en.wikipedia.org/wiki/React_(JavaScript_library)",
	},
	Java: {
		icon: javaIcon,
		tooltipText: "Java",
		tooltipLink:
			"https://en.wikipedia.org/wiki/Java_(programming_language)",
	},
	Python: {
		icon: pythonIcon,
		tooltipText: "Python",
		tooltipLink:
			"https://en.wikipedia.org/wiki/Python_(programming_language)",
	},
	Docker: {
		icon: dockerIcon,
		tooltipText: "Docker",
		tooltipLink: "https://en.wikipedia.org/wiki/Docker_(software)",
	},
};

export const svEnProjects: SvEnProjects = {
	"The Card Game": {
		name: {
			sv: "The Card Game",
			en: "The Card Game",
		},
		text: {
			sv: "En chattapplikation som används för att samla in data om hur moral förändras med anonymitet.",
			en: "A chat application used to collect data about how morals change with anonymity.",
		},
		textLong: {
			sv: `I vår kandidatexamen undersökte vi hur människors moral förändras med anonymitet. Vi skapade denna 
            chattapplikation för att samla in data om användarnas beteende. Applikationen tillät oss att testa hur 
            användare betedde sig när de var på olika nivåer av anonymitet.`,
			en: `In our bachelor's thesis, we examined how people's morals change with anonymity.
            We created this chat application to collect data about user behavior. The application allowed us to
            test how users behaved when they were exposed to different levels of anonymity.`,
		},

		bullets: [
			{
				sv: `Detta var det första projektet där jag arbetade med Firebase. Vi använde Firebase:s 
                Firestore-databas för att visa chattmeddelanden för användarna i realtid.`,
				en: `This was the first project where I got to work with Firebase. We used Firebase's firestore database
                in order to display the chat messages to the users in real-time.`,
			},
		],
		mainLink: "https://rrudling.github.io/thecardgame/",
		demoLink: "https://rrudling.github.io/thecardgame/",
		gitHubLink: "https://github.com/rrudling/thecardgame",
		images: [
			{
				original: theCardGame1,
				thumbnail: theCardGame1Low,
			},
			{
				original: theCardGame2,
				thumbnail: theCardGame2Low,
			},
			{
				original: theCardGame3,
				thumbnail: theCardGame3Low,
			},
		],
		technologies: ["CSS", "HTML", "JavaScript", "ReactJS", "Firebase"],
		type: {
			sv: "Skolprojekt",
			en: "School project",
		},
	},
	"Chess App": {
		name: {
			sv: "Schackapplikation",
			en: "Chess App",
		},
		text: {
			sv: "Den här schackapplikationen utvecklades som ett projekt i en Java-kurs.",
			en: "This chess application was developed as a project in a Java course.",
		},
		textLong: {
			sv: `I kursen <i> DD1385 Software Engineering </i> blev jag introducerad till objektorienterad programmering 
           i Java. Som ett avslutande projekt i kursen skapade jag tillsammans med en annan student en grafisk 
           schackapplikation.`,
			en: `In the course <i>DD1385 Software Engineering</i> I got introduced to object-oriented programming
           in Java. As a final project in the course, I together with another student created a graphical chess application.`,
		},
		bullets: [],
		mainLink: "https://github.com/rrudling/java-chess",
		demoLink: undefined,
		gitHubLink: "https://github.com/rrudling/java-chess",
		images: [
			{
				original: chessApp1,
				thumbnail: chessApp1Low,
			},
			{
				original: chessApp2,
				thumbnail: chessApp2Low,
			},
		],
		technologies: ["Java"],
		type: {
			sv: "Skolprojekt",
			en: "School project",
		},
	},
	"Learn AI": {
		name: {
			sv: "Learn AI",
			en: "Learn AI",
		},
		text: {
			sv: "En interaktiv hemsida för att underlätta inlärningen av AI-koncept för andra masterstudenter.",
			en: "An interactive website to ease the learning of AI concepts for other master students.",
		},
		textLong: {
			sv: `Skapade en hemsida för att underlätta inlärningen av AI-koncept för andra studenter. Efter att 
            ha gått kursen <i> DD2380 Artificiell intelligens </i> på KTH kände jag att jag hade förstått en hel del 
            av de grundläggande begreppen inpm AI. Därför bestämde jag mig för att skapa denna hemsida. Dels för mitt eget 
            lärande, men främst för att hjälpa andra elever att förstå begreppen bättre.`,
			en: `Created a website to ease the learning of AI concepts for other students.
            After taking the course <i>DD2380 Artificial Intelligence</i> at KTH I felt that I had understood
            a lot of the basic concepts of AI. Therefore, I decided to create this website. Partly for my own learning,
            but mainly to help other students understand the concepts better.`,
		},
		bullets: [
			{
				sv: `Skapade många komponenter i detta projekt. Det gjorde mig bekväm att arbeta 
                med många av de olika aspekterna av react.`,
				en: `Created a lot of components in this project. This made me comfortable working with
                many of the different aspects of react.`,
			},
			{
				sv: "Detta var det första projektet jag jobbade med ett betalt domännamn.",
				en: `This was the first project that I hosted with a paid domain name.`,
			},
		],
		mainLink: "https://learn-ai.web.app/",
		demoLink: "https://learn-ai.web.app/",
		gitHubLink: "https://github.com/rrudling/Learn-AI",
		images: [
			{
				original: learnAi1,
				thumbnail: learnAi1Low,
			},
			{
				original: learnAi2,
				thumbnail: learnAi2Low,
			},
			{
				original: learnAi3,
				thumbnail: learnAi3Low,
			},
			{
				original: learnAi4,
				thumbnail: learnAi4Low,
			},
			{
				original: learnAi5,
				thumbnail: learnAi5Low,
			},
			{
				original: learnAi6,
				thumbnail: learnAi6Low,
			},
			{
				original: learnAi7,
				thumbnail: learnAi7Low,
			},
			{
				original: learnAi8,
				thumbnail: learnAi8Low,
			},
			{
				original: learnAi9,
				thumbnail: learnAi9Low,
			},
		],
		technologies: ["Sass", "HTML", "JavaScript", "ReactJS", "Firebase"],
		type: {
			sv: "Personligt projekt",
			en: "Personal project",
		},
	},
	"Chapter Website": {
		name: {
			sv: "Sektionshemsida",
			en: "Chapter Website",
		},
		text: {
			sv: "Byggde en helt ny hemsida för Sektionen för Medieteknik på KTH.",
			en: "Built brand new website for the Media Technology Chapter at KTH.",
		},
		textLong: {
			sv: `Arbetade med ett team på nio utvecklare och designers för att bygga en ny hemsida för Sektionen för Medieteknik på KTH. 
            Arbetade mestadels på dokumentsidan, så att våra sektionsmedlemmar kunde hitta dokument snabbare. Arbetade också på 
            eventsidan, så att sektionsmedlemmarna kunde hitta alla tillgängliga evenemang på ett ställe. Tidigare spreds eventinformationen ut på Facebook.`,
			en: `Worked with a team of nine developers and designers to build a new 
            website for the Media Technology Chapter at KTH. Worked mostly on the document page, allowing our 
            chapter members to find documents faster. Also worked on the event 
            page, allowing chapter members to find all the available events in one place. Previously, 
            the event information were spread out on Facebook.`,
		},
		bullets: [
			{
				sv: "Detta var det första projektet där jag använde både React och Sass.",
				en: `This was the first project where I got to use both React and Sass.`,
			},
			{
				sv: "Lärde mig mycket om hur det är att arbeta i ett tekniskt team.",
				en: `Learned a lot about how it is to work in a technical team.`,
			},
			{
				sv: "När vi var färdiga med den första versionen av hemsidan blev det ett projekt med öppen källkod.",
				en: "When we were finished with the first version of the website it became an open-source project.",
			},
		],
		mainLink: "https://www.medieteknik.com/documents",
		demoLink: "https://www.medieteknik.com/documents",
		gitHubLink: "https://github.com/medieteknik-kth/medieteknik.com",
		images: [
			{
				original: chapterWebsite1,
				thumbnail: chapterWebsite1Low,
			},
			{
				original: chapterWebsite2,
				thumbnail: chapterWebsite2Low,
			},
			{
				original: chapterWebsite3,
				thumbnail: chapterWebsite3Low,
			},
			{
				original: chapterWebsite4,
				thumbnail: chapterWebsite4Low,
			},
			{
				original: chapterWebsite5,
				thumbnail: chapterWebsite5Low,
			},
		],
		technologies: ["Sass", "HTML", "JavaScript", "ReactJS"],
		type: {
			sv: "Professionellt projekt",
			en: "Professional project",
		},
	},
	"RL lab": {
		name: {
			sv: "RL labb",
			en: "RL lab",
		},
		text: {
			sv: "Använde Python för att vidareutveckla en labbövning gällande reinforcement learning för masterstudenter.",
			en: "Use Python to further develop a lab exercise regarding reinforcement learning for master students.",
		},
		textLong: {
			sv: `Efter att ha gått kursen <i> DD2380 Artificiell intelligens </i> på KTH började jag arbeta som lärarassistent i kursen. 
            Innan nästa kursomgång började utvecklade jag ytterligare en av de tre labbuppgifterna som handlade om reinforcement learning.`,
			en: `After taking the course <i>DD2380 Artificial Intelligence</i> at KTH I started working as a 
            teaching assistant in the course. Before the next course round started, I further developed one of the three
            lab assignments which were about reinforcement learning.`,
		},
		bullets: [
			{
				sv: `När jag utvecklade labben lärde jag mig hur man kan använda shell-scripts för att skapa ett mer effektivt arbetsflöde. När
                jag testade koden använde jag docker, det var då shell-scripts gjorde våra liv enklare eftersom vi inte behövde
                kopiera filer manuellt hela tiden.`,
				en: `When developing the lab, I learned how one could use shell scripts to create a more efficient workflow. When
                testing the code I used docker, that's when the shell scripts made our lives easier since we didn't need
                to manually copy files all the time.`,
			},
			{
				sv: `Detta var det första projektet som jag arbetade med som jag inte var en del av från början. Jag lärde mig mycket om
                hur man förstår stora kodbaser och hur man gradvis lägger till nya funktioner.`,
				en: `This was the first project that I worked on that I didn't was part of from the start. I learned a lot about
                how to understand big codebases and how to gradually add new features.`,
			},
		],
		mainLink: "https://kth.kattis.com/problems/kth.ai.rl1",
		demoLink: "https://kth.kattis.com/problems/kth.ai.rl1",
		gitHubLink: undefined,
		images: [
			{
				original: rlLab1,
				thumbnail: rlLab1Low,
			},
			{
				original: rlLab2,
				thumbnail: rlLab2Low,
			},
			{
				original: rlLab3,
				thumbnail: rlLab3Low,
			},
		],
		technologies: ["Python", "Docker"],
		type: {
			sv: "Professionellt projekt",
			en: "Professional project",
		},
	},
	SwiftWriter: {
		name: {
			sv: "SwiftWriter",
			en: "SwiftWriter",
		},
		text: {
			sv: "En applikation gjord för att hjälpa människor att skriva snabbare.",
			en: "An application made to helper people type faster.",
		},
		textLong: {
			sv: `SwiftWriter är ett program som hjälper människor att skriva snabbare på ett roligt och frustrationsfritt sätt. 
            Jag började arbeta med det här projektet eftersom jag ville skapa något som var användbart för andra. För närvarande 
            har jag inte tid att arbeta med det här projektet, men jag är taggad på att fortsätta arbeta med den här applikationen 
            när jag har möjlighet till det.`,
			en: `SwiftWriter is an application that will help people write faster in a fun and frustration-free manner.
            I started to work on this project because I wanted to create something that was
            useful for others. Currently, I don't have the time to work on this project, but I'm excited to
            continue working on this application when I have more time.`,
		},
		bullets: [
			{
				sv: "I det här projektet skapade jag mitt första kontext i React. Jag använder den för att lagra användarens val av språk.",
				en: `In this project, I created my first react context. I use it to store the user's choice of language.`,
			},
		],
		mainLink: "https://swiftwriter.web.app/",
		demoLink: "https://swiftwriter.web.app/",
		gitHubLink: "https://github.com/rrudling/swift-writer",
		images: [
			{
				original: swiftWriter1,
				thumbnail: swiftWriter1Low,
			},
			{
				original: swiftWriter2,
				thumbnail: swiftWriter2Low,
			},
			{
				original: swiftWriter3,
				thumbnail: swiftWriter3Low,
			},
			{
				original: swiftWriter4,
				thumbnail: swiftWriter4Low,
			},
			{
				original: swiftWriter5,
				thumbnail: swiftWriter5Low,
			},
		],
		technologies: ["Sass", "HTML", "JavaScript", "ReactJS", "Firebase"],
		type: {
			sv: "Personligt projekt",
			en: "Personal project",
		},
	},
	"Rudling Tech": {
		name: {
			sv: "Rudling Tech",
			en: "Rudling Tech",
		},
		text: {
			sv: "Min personliga hemsida.",
			en: "My personal website.",
		},
		textLong: {
			sv: `Jag har skapat denna hemsida främst för att ha en plats där jag kan visa upp mitt tidigare arbete. Men
            också för att kunna skriva blogginlägg om programmering och annan teknik som jag tycker är intressant.`,
			en: `I've created this website mainly to have a place where I can showcase my previous work. But 
            also to be able to write blog posts about programming and other technologies I find interesting.`,
		},
		bullets: [
			{
				sv: `I det här projektet har jag fokuserat mycket på responsiv design och skapat en sömlös upplevelse både 
                för desktop- och mobil-användare.`,
				en: `In this project I've focused a lot on responsive design, creating a seamless experience both for
                desktop and mobile users.`,
			},
			{
				sv: "Detta är det första projektet jag har arbetat med där jag använder TypeScript.",
				en: `This is the first project I've worked on where I use TypeScript.`,
			},
		],
		mainLink: "https://rudling.tech",
		demoLink: "https://rudling.tech",
		gitHubLink: "https://github.com/rrudling/rudling-tech",
		images: [
			{
				original: rudlingTech1,
				thumbnail: rudlingTech1Low,
			},
			{
				original: rudlingTech2,
				thumbnail: rudlingTech2Low,
			},
		],
		technologies: ["Sass", "HTML", "TypeScript", "ReactJS", "Firebase"],
		type: {
			sv: "Personligt projekt",
			en: "Personal projekt",
		},
	},
	ShareTunes: {
		name: {
			sv: "ShareTunes",
			en: "ShareTunes",
		},
		text: {
			sv: `Ett socialt medie där du kan skapa en profil och dela din favoritmusik med vänner.`,
			en: `A musical sharing social media website where you can create a profile and share your favorite 
            music with friends.`,
		},
		textLong: {
			sv: `Ett socialt medie där du kan skapa en profil och dela din favoritmusik med 
            vänner. Du kan både kommentera, gilla, skapa inlägg och följa andra användare och lyssna på 
            förhandsgranskningar av låtar som hämtats från Spotify:s API.`,
			en: `A musical sharing social media website where you can create a profile and share your favorite music with 
            friends. You can both comment, like, create posts and follow other users and listen to previews of songs fetched from 
            the Spotify API.`,
		},
		bullets: [
			{
				sv: "Jag tillbringade mycket tid med att arbeta med Spotifys API. Detta breddade min förståelse för webbutveckling.",
				en: `I spent a lot of time working with Spotify's API. This broadened my understanding of web development.`,
			},
			{
				sv: `Jag tog ett stort ansvar för backend:en i det här projektet. Eftersom vår applikation hade mycket dynamiskt innehåll baserat
                på den för närvarande inloggade användaren var det en givande upplevelse att lära sig olika aspekter av backendutveckling.`,
				en: `I took a big responsibility for the backend of this project. Since our application had a lot of dynamic content based 
                on the currently logged-in user, this was a very fruitful experience to learn different aspects of backend development.`,
			},
		],
		mainLink: "https://github.com/charlottea98/ShareTunes",
		demoLink: "https://sharetunesmedia.web.app/",
		gitHubLink: "https://github.com/charlottea98/ShareTunes",
		images: [
			{
				original: shareTunes2,
				thumbnail: shareTunes2Low,
			},
			{
				original: shareTunes1,
				thumbnail: shareTunes1Low,
			},
			{
				original: shareTunes3,
				thumbnail: shareTunes3Low,
			},
			{
				original: shareTunes4,
				thumbnail: shareTunes4Low,
			},
			{
				original: shareTunes5,
				thumbnail: shareTunes5Low,
			},
			{
				original: shareTunes6,
				thumbnail: shareTunes6Low,
			},
			{
				original: shareTunes7,
				thumbnail: shareTunes7Low,
			},
			{
				original: shareTunes8,
				thumbnail: shareTunes8Low,
			},
		],
		technologies: ["Sass", "HTML", "TypeScript", "ReactJS", "Firebase"],
		type: {
			sv: "Skolprojekt",
			en: "School project",
		},
	},
	"Image Colorizer with DL": {
		name: {
			sv: "Bildfärgare med DL",
			en: "Image Colorizer with DL",
		},
		text: {
			sv: `Målet med detta projekt var att studera hur en gråskalig bild kan färgläggas med hjälp av ett 
            konvolutionellt neuralt nätverk (CNN).`,
			en: `The goal of this project was to study how a grayscale image can be colorized using a convolutional 
            neural network (CNN)`,
		},
		textLong: {
			sv: `<b> Abstrakt:</b> Målet med detta paper är att studera hur en gråskalig bild kan färgläggas med 
            hjälp av ett konvolutionellt neuralt nätverk (CNN). Metoden som presenterades i papperet Colorful Image 
            Colorization, skriven av R. Zhang et al., Användes som referens. Data som används för att träna 
            modellen som presenteras i detta dokument är begränsad till porträttbilder av människors ansikten. 
            Datauppsättningen som används i denna studie är således smalare än vad som används i originalpappret, 
            vilket är en mängd olika bildkategorier. Två olika förlustfunktioner implementeras med resultat som visar 
            att en multinomisk korsentropiförlustfunktion ger mycket bättre resultat än en euklidisk förlustfunktion. 
            Även om den har tränats för mycket färre iterationer, färgar modellen som presenteras i detta dokument 
            porträttbilder mer realistiskt i 58% av fallen jämfört med modellen som presenteras av R. Zhang et al. i 
            en kvalitativ studie.`,
			en: `<b>Abstract:</b> The goal of this paper is to study how a grayscale image can be 
            colorized using a convolutional neural network (CNN). The method presented in the paper Colorful
            Image Colorization, written by R. Zhang et al., was used as reference. The
            data used to train the model presented in this paper is confined to portrait images
            of people’s faces. The dataset used in this study is thus more narrow than what
            is used in the original paper, which is a wide variety of image categories. Two
            different loss functions are implemented with results showing that a multinomial
            cross-entropy loss function produces much better results than a euclidian loss
            function. Although it has been trained for far fewer iterations, the model presented
            in this paper colorizes portrait images more realistically in 58% of the cases when
            compared to the model presented by R. Zhang et al. in a qualitative study.`,
		},
		bullets: [],
		mainLink: "https://github.com/Gnabriel/Image-Colorization",
		demoLink: undefined,
		gitHubLink: "https://github.com/Gnabriel/Image-Colorization",
		images: [
			{
				original: imageColorization1,
				thumbnail: imageColorization1Low,
			},
			{
				original: imageColorization2,
				thumbnail: imageColorization2Low,
			},
			{
				original: imageColorization3,
				thumbnail: imageColorization3Low,
			},
		],
		technologies: ["Python"],
		type: {
			sv: "Skolprojekt",
			en: "School project",
		},
	},
};
